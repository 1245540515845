.service-template {
  width: 100%;
  padding: 40px 10px;
  background-color: antiquewhite;
}
.service-template-div-1 {
  width: 100%;

  min-height: 95vh;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 60px;
}

.intro-img-div {
  width: 480px;
  height: 480px;
}
.intro-img-div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transition: 0.3s all ease-in-out;
}
.intro-img-div img:hover {
  padding: 0;
}
.service-intro-content-div {
  width: 600px;
}
.service-intro-content-div p {
  text-overflow: clip;
  font-size: 19px;
  color: rgb(54, 54, 54);
  text-align: justify;
}
.service-intro-content-div h1 {
  font-size: 30px;
  margin-bottom: 2rem;
}
.service-intro-content-div b {
  margin-top: 1rem;
  font-size: 19px;
  letter-spacing: 1px;
}

/* service-toWhom */

.service-process-div {
  width: 100%;
  padding: 40px 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  row-gap: 100px;
}

.service-toWhom-text {
  width: calc(33.33% - 10px);
  height: fit-content;
  padding: 0 20px;
  display: flex;

  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  text-align: left;
  row-gap: 20px;
}
.service-toWhom-text h1 {
  font-size: 20px;
}

.service-toWhom-text h2 {
  font-size: 18px;
  font-weight: 500;
  color: rgb(20, 20, 20);
}

.service-toWhom-points {
  display: flex;
  flex-direction: column;
}
.service-toWhom-points p {
  margin: 6px 0;
  font-size: 16px;
  border-bottom: 1px solid rgb(228, 223, 223);
}

@media only screen and (max-width: 767px) {
  .service-template {
    width: 100%;
    padding-top: 100px;
  }
  .service-template-div-1 {
    width: 100%;

    min-height: 50vh;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }

  .intro-img-div {
    width: 100%;
    height: 100%;
  }
  .intro-img-div img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 1px solid black;
  }
  .service-intro-content-div {
    width: 100%;
    padding: 0 10px;
  }
  .service-intro-content-div p {
    text-overflow: clip;
    font-size: 17px;
    color: rgb(54, 54, 54);
  }
  .service-intro-content-div h1 {
    font-size: 25px;
    margin-bottom: 1rem;
  }
  .service-intro-content-div b {
    margin-top: 1rem;
    font-size: 19px;
    letter-spacing: 1px;
    text-align: center;
  }

  .service-process-div {
    margin-top: 1rem;
    row-gap: 50px;
  }

  .service-toWhom-text {
    width: 100%;
  }
}
