.sp-main-div {
  width: 100%;

  padding:40px 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  
  background-color: antiquewhite;
}
.sp-main-div h1 {
  font-size: 40px;
  color:black;
}
.ServicesPortfolio-div {
  margin: 1rem 0;
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.ServicesPortfolio-card {
  width:calc(25% - 10px);
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* border: 1px solid rgb(35, 120, 4); */
}
.ServicesPortfolio-card h3 {
  font-size: 18px;
  margin: 10px auto;
  font-weight:bold;
  text-align: center;
  text-wrap: nowrap;
  color:maroon;
  cursor: pointer;
}

.ServicesPortfolio-card img {
  width: 100%;
  max-width: 150px;
  padding: 1rem;
  
  border: 2px solid maroon;
  border-radius: 1rem;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 1);
  transition: 0.3s all ease-in-out;

}
.ServicesPortfolio-card img:hover {
  padding: 0.5rem;
  cursor: pointer;
 

}
@media only screen and (max-width: 960px) {
  .sp-main-div {
   
  
    padding:40px 0;
    
    gap: 5px;
  }
  .ServicesPortfolio-div {
    width: 100%;
    max-width: 100%;
    margin: 1rem auto;
    gap: 0;
    justify-content: space-between;
  align-items:center;
  }
  .sp-main-div h1 {
    font-size: 30px;
  }
  .ServicesPortfolio-card {
    width: calc(50% - 10px);
  }

  .ServicesPortfolio-card img {
    max-width: 80px;
    padding: 0.5rem;
    
    
  }
  .ServicesPortfolio-card h3 {
    font-size: 16px;
    margin: 3px auto;
    text-wrap: wrap;
  }
}
