
.intro-div {
    width: 100%;
    min-height: 85vh;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;
    background-color: rgb(248, 252, 255);
    
  }
  
  .intro-video-div {
    width: 560px;
    
  }
  .intro-video-div img{
    width: 85%;

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
    border-radius: 0.6rem;

  }
  .intro-content-div{
    width: 500px;
  }
  .intro-content-div h1{
    margin-bottom: 1rem;
    color: rgb(58, 58, 58);

  }
  .intro-content-div p {
    text-overflow: clip;
    font-size: 18px;
    color: rgb(68, 68, 68);
    text-align: justify;
  }


  @media only screen and (max-width: 768px) {
    .intro-div {
      
      padding:40px 15px;
      
     
    }
    .intro-video-div {
      width: 100%;
      
    }
    .intro-video-div img{
      width: 100%;
  
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
      border-radius: 0.6rem;
  
    }

    
    
   

  }