footer {
  width: 100%;
  min-height: fit-content;
  /* border: 1px solid red; */
  margin: auto;
  margin-bottom: 80px;
  background-color: black;
  color: white;
}

.training-div {
  display: flex;
  padding: 20px;
  background-color: white;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

.training-div img {
  max-width: 400px;
  width: 100%;
  object-fit: cover;
  border-radius: 6px;
}

.footer-top {
  width: 100%;
  min-height: fit-content;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin: auto;
  gap: 10px;
  /* border: 1px solid maroon; */
}
.footer-1 {
  width: 30%;
  height: 350px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: 1px solid yellow; */
}
.footer-1 img {
  width: 100%;
  max-width: 100px;
  object-fit: cover;
  padding: 0.3rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transition: 0.3s all ease-in-out;
}
.footer-1 img:hover {
  padding: 0;
}

.footer-2 {
  width: 30%;
  height: 100%;
  padding: 10px;
  /* border: 1px solid yellow; */
}
.footer-3 {
  width: 30%;
  height: 100%;
  padding: 10px;
  /* border: 1px solid yellow; */
}
.s-inner-div-1 {
  width: calc(100% - 10px);
  margin-top: 25px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
}

.s-inner-div-1 p {
  font-size: 18px;
  cursor: pointer;
  display: flex;
}
.s-inner-div {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  gap: 12px;
}
.s-inner-div span {
  margin: 10px 0;
}
.s-inner-div p {
  font-size: 18px;
  font-weight: 450;
}

.s-inner-div a {
  text-decoration: none;
  color: white;
}
.footer-div-3 {
  margin-top: 30px;
  width: fit-content;
  display: flex;

  justify-content: space-between;
  align-items: flex-start;
  /* border: 1px solid red; */
}
.social-media-2 {
  width: 35px;
  margin: 0 10px;
}
.social-media-2 img {
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.social-media-2 img:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.copyright {
  width: 100%;
  height: 10vh;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  color: grey;
  text-align: center;
  /* border: 2px solid green; */
}

.policy-div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  font-size: 1.3em;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  cursor: pointer;
}

.button3 {
  display: inline-block;
  transition: all 0.2s ease-in;
  position: relative;
  overflow: hidden;
  z-index: 1;
  margin-top: 1rem;
  color: white;
  padding: 15px 20px;
  cursor: pointer;
  font-size: 19px;
  font-weight: 500;
  border-radius: 0.5em;
  background: black;
  border: 1px solid #e8e8e8;
  /* box-shadow: 3px 3px 6px #c5c5c5; */
}

.button3:active {
  color: #666;
  box-shadow: inset 4px 4px 12px #c5c5c5, inset -4px -4px 12px #ffffff;
}

.button3:before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%) scaleY(1) scaleX(1.25);
  top: 100%;
  width: 140%;
  height: 180%;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  display: block;
  transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -1;
}

.button3:after {
  content: "";
  position: absolute;
  left: 55%;
  transform: translateX(-50%) scaleY(1) scaleX(1.45);
  top: 180%;
  width: 160%;
  height: 190%;
  background-color: #009087;
  border-radius: 50%;
  display: block;
  transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -1;
}

.button3:hover {
  color: #ffffff;
  border: 1px solid #009087;
}

.button3:hover:before {
  top: -35%;
  background-color: #009087;
  transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
}

.button3:hover:after {
  top: -45%;
  background-color: #009087;
  transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
}

@media only screen and (max-width: 960px) {
  .footer-top {
    width: 100% !important;
  }
  .footer-1 {
    width: 100%;
    height: 230px;
  }

  .footer-2 {
    width: 100%;
  }
  .footer-3 {
    width: 100%;
  }
  .s-inner-div-1 {
    width: calc(100% - 10px);
  }
}

@media only screen and (max-width: 650px) {
  footer {
    margin-bottom: 150px;
  }
}
