header {
  width: 100%;
  height: 111px;
  position: fixed;
  border-bottom: 1px solid black;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 10000000000000;
  column-gap: 10px;
  /* color: white; */
  /* background-color: rgb(232, 238, 248); */
  /* backdrop-filter: blur(100px); */
  background-color: rgb(246, 249, 255);
}
.nav-div-1 {
  height: 100%;
  width: 350px;
  gap: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  /* border: 2px solid orange; */
}
.company-logo-div {
  width: 80px;
  /* border: 2px solid black; */
}
.company-logo-div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 0.2rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transition: 0.2s all ease-in-out;
}
.company-logo-div img:hover {
  padding: 0;
}
.title-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.title {
  font-size: 30px;
}
.title-quote {
  text-wrap: nowrap;
}
.nav-center-div {
  width: 50%;
  height: 100%;

  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  /* border: 3px solid red; */
  padding: 0.5rem 0;
}
.nav-div-2 {
  height: fit-content;
  width: 100%;
  /* border: 1px solid black; */
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  justify-content: space-evenly;
  align-items: center;
}
.nav-contact {
  width: 480px;
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 30px;
  margin-right: 15px;
}
.contact-div-1 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact-div-1 img {
  width: 20px;
  max-width: 100%;
  object-fit: cover;
  /* border: 1px solid black; */
  margin-right: 5px;
}
.contact-div-1 a {
  font-size: 1.1em;
  font-weight: 600;
  text-decoration: none;
  color: black;
}
.nav-div-3 {
  width: 200px;
  display: flex;
  /* border: 1px solid black; */
  justify-content: center;
  align-items: center;
  /* border: 1px solid red; */
}
.social-media-1 {
  width: 35px;
  margin: 0 10px;

  /* border: 1px solid black; */
}
.social-media-1 img {
  width: 100%;
  object-fit: cover;
  border-radius: 50%;

  /* border: 1px solid black; */
}
.social-media-1 img:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.router-div {
  padding: 0 1.5rem;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.router-div p {
  font-size: 17px;
  font-weight: bold;

  position: relative;
}
.active {
  color: maroon;
  text-decoration: underline;
}
.router-div p:hover {
  cursor: pointer;
  text-decoration: underline;
}

.dropdownmenu,
.dropdownmenu2 {
  display: none;
}
.router-div p:hover .dropdownmenu {
  display: block;
  position: absolute;
  left: 0;
  top: 100%;
  width: fit-content;
  height: fit-content;

  background-color: rgb(250, 244, 239);
}
.dropdownmenu p:hover {
  background-color: black;
  color: white;
  text-decoration: none;
}
.dropdownmenu2 {
  color: black;
}
.router-div p:hover .dropdownmenu p {
  width: 220px;
  padding: 10px;
  margin: 1px 0;
}
.dropdownmenu p:hover .dropdownmenu2 {
  display: block;
  position: absolute;
  top: 0;
  left: 222px;
  background-color: rgb(246, 237, 226);
}

.fas {
  float: right;
  margin-left: 5px;
  padding-top: 3px;
}

.nav-btn {
  margin: 0 10px;
}
.nav-btn button {
  padding: 1rem;
  width: fit-content;
  text-wrap: nowrap;
  font-size: 1em;
  font-weight: bold;
  background-color: black;
  color: white;
  cursor: pointer;
}

.open-menu,
.close-menu {
  position: absolute;
  cursor: pointer;
  display: none;
}
#check {
  display: none;
}

@media only screen and (max-width: 1023px) {
  .nav-div-1 {
    width: fit-content;
    gap: 5px;
  }
  .company-logo-div {
    width: 60px;
    margin-left: 10px;
    /* border: 2px solid black; */
  }
  .title {
    font-size: 22px;
  }
  .title-quote {
    font-size: 14px;
  }
  .nav-div-2 {
    display: none;
  }
  .nav-btn {
    display: none;
  }
  .nav-div-1 {
    height: 100%;
    width: 100%;
    gap: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 2px solid orange; */
  }
  .router-div {
    position: fixed;
    top: 111px;
    right: 0;
    width: 70%;
    height: fit-content;
    background-color: #fff; /* You can change this color */
    z-index: 9999;
    padding: 20px;
    overflow-y: auto;
    display: none;
  }

  .router-div.open {
    display: block;
  }

  .router-div p {
    font-size: 18px; /* Adjust size as needed */
    margin-bottom: 15px;
    cursor: pointer;
  }

  .router-div .dropdownmenu,
  .router-div .dropdownmenu2 {
    display: none;
    padding-left: 20px;
  }
  .router-div p:hover .dropdownmenu {
    position: static;
    margin: 0px;
  }
  .router-div p:hover .dropdownmenu p {
    padding: 0px;
  }
  .dropdownmenu p:hover .dropdownmenu2 {
    position: static;
    top: 0;
    left: 0;
    background-color: white;
  }

  .open-menu {
    display: block;
    position: fixed;
    top: 40px;
    font-size: 30px;
    right: 10px;
    cursor: pointer;
    z-index: 99999; /* Ensure it's above other elements */
  }

  .close-menu {
    display: none;
    position: fixed;
    top: 40px;
    font-size: 30px;
    right: 10px;
    cursor: pointer;
    z-index: 99999; /* Ensure it's above other elements */
  }

  /* Style for open/close menu checkbox */
  #check {
    display: none;
  }

  #check:checked ~ .router-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  #check:checked ~ .open-menu {
    display: none;
  }

  #check:checked ~ .close-menu {
    display: block;
  }
}
