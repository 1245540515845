.why-div {
    width: 100%;
    min-height:60vh ;
    display: flex;
    flex-direction: column;
    justify-content:center;
    gap: 30px;
    padding: 40px 10px;
   
    align-items: center;
    background-color: rgb(255, 255, 255);    
  }
  .why-div h1{
    font-size: 40px;
    text-align: center;
  }
  .why-content-div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 4rem;
  }
  
  .why-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;

  }
 
  .why-center-line{
    
    height: 50px;
    border-right:0.1px solid rgb(146, 146, 146) ;

  }
  
  .why-content p {
    max-width: 203px;
    margin: 5px;
    font-size: 18px;
    font-weight: 700;
    padding: 5px;
    text-align: center;
    color: crimson;
  }
  @media only screen and (max-width: 960px) {
    .why-div h1{
        font-size: 32px;
        
      }
    .why-center-line{
        display: none;
  }
  .why-content-div {
    
    gap: 1.5rem;
  }
}