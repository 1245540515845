.webdev-main{
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
    padding: 20px 10px;
    background-color: antiquewhite;


}
.web1{
    width: 100%;
    padding: 80px 0 0 0;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    
    

}
.web1 h1{
    font-size: 45px;
    margin: 0 0 3rem 0;
}
.workProfile{
    width: 70%;
    display: flex;
    gap: 1rem;
    justify-content: center;
    flex-wrap: wrap;

}
.pr1{
    width: 450px;
    
    border-radius: 6px;
}
.pr1 img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
    transition: 0.2s ease-in;
    border: 1px solid black;
    padding: 0.2rem;
   

}
.pr1 img:hover{
    padding: 0;
}
@media only screen and (max-width: 768px) {
    
    .pr1{
        width: 100%;
    }
    .workProfile{
        width: 100%;
    }
    .web1 h1{
        font-size: 36px;
        
    }
}