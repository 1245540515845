.containerhome {
  width: 100%;
  overflow: hidden;
  height: 100vh;
  position: relative;
}

.banner1,
.banner2,
.banner3,
.banner4 {
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: cover;
  left: 0;
  top: 111px;
}
.banner1 img,
.banner2 img,
.banner3 img,
.banner4 img {
  width: 120%;
  height: 100%;
  object-fit: cover;
  position: absolute;

  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: zoom-out 4s linear infinite;
}
@keyframes zoom-out {
  100% {
    width: 100%;
  }
}
.textboxmaindiv {
  width: 100%;
  height: 100%;
  background-color: rgba(14, 46, 86, 0.5);
  background-size: cover;
  position: relative;
}
.text-box {
  width: fit-content;
  position: absolute;
  top: 200px;
  left: 100px;
  color: white;
  animation: textup 16s linear infinite;
  transform: translateY(100px);
  /* z-index: 2; */
}
.text-box h1 {
  font-size: 40px;
  margin: 15px 0 20px 0;
  color: rgb(254, 153, 2);
  color: #fff;
  /* -webkit-text-stroke: 1px black;  */
  text-shadow: 1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000,
    -1px 1px 0 #000;
}
.text-box p {
  font-size: 20px;
  font-weight: bold;

  color: #fff;
  /* -webkit-text-stroke: 1px black;  */
  text-shadow: 1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000,
    -1px 1px 0 #000;
}
.button2 {
  display: inline-block;
  transition: all 0.2s ease-in;
  position: relative;
  overflow: hidden;
  z-index: 1;
  margin-top: 1rem;
  color: #090909;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 0.5em;
  background: #e8e8e8;
  border: 1px solid #e8e8e8;
  /* box-shadow: 3px 3px 6px #c5c5c5; */
}

.button2:active {
  color: #666;
  box-shadow: inset 4px 4px 12px #c5c5c5, inset -4px -4px 12px #ffffff;
}

.button2:before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%) scaleY(1) scaleX(1.25);
  top: 100%;
  width: 140%;
  height: 180%;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  display: block;
  transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -1;
}

.button2:after {
  content: "";
  position: absolute;
  left: 55%;
  transform: translateX(-50%) scaleY(1) scaleX(1.45);
  top: 180%;
  width: 160%;
  height: 190%;
  background-color: #009087;
  border-radius: 50%;
  display: block;
  transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -1;
}

.button2:hover {
  color: #ffffff;
  border: 1px solid #009087;
}

.button2:hover:before {
  top: -35%;
  background-color: #009087;
  transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
}

.button2:hover:after {
  top: -45%;
  background-color: #009087;
  transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
}

@keyframes textup {
  10% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(0px);
  }
}
.text-box1 {
  animation-delay: 0s;
}
.text-box2 {
  animation-delay: 4s;
}
.text-box3 {
  animation-delay: 8s;
}
.text-box4 {
  animation-delay: 12s;
}
.banner1 {
  animation: slide1 16s linear infinite;
}
.banner2 {
  animation: slide2 16s linear infinite;
}
.banner3 {
  animation: slide3 16s linear infinite;
}
.banner4 {
  animation: slide4 16s linear infinite;
}
@keyframes slide1 {
  0% {
    visibility: visible;
  }
  25% {
    visibility: hidden;
  }
  50% {
    visibility: hidden;
  }
  75% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

@keyframes slide2 {
  0% {
    visibility: hidden;
  }
  25% {
    visibility: hidden;
  }
  50% {
    visibility: visible;
  }
  75% {
    visibility: hidden;
  }
  100% {
    visibility: hidden;
  }
}

@keyframes slide3 {
  0% {
    visibility: hidden;
  }
  25% {
    visibility: hidden;
  }
  50% {
    visibility: hidden;
  }
  75% {
    visibility: visible;
  }
  100% {
    visibility: hidden;
  }
}

@keyframes slide4 {
  0% {
    visibility: hidden;
  }
  25% {
    visibility: hidden;
  }
  50% {
    visibility: hidden;
  }
  75% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}
@media only screen and (max-width: 960px) {
  .containerhome {
    height: 60vh;
  }
  .text-box {
    top: 25%;
    left: 30px;
  }
  .text-box h1 {
    font-size: 30px;
    margin: 10px 0 15px 0;
  }
  .text-box p {
    font-size: 16px;
  }
  .text-box button {
    margin-top: 16px;
    padding: 5px 10px;
    font-size: 16px;
  }
}
