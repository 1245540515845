.container {
  display: flex;
  flex-wrap: wrap;
  background-color: rgb(0, 0, 0);
  justify-content: space-around;
  align-items: center;
  color: white;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  min-height: 80vh;
  padding: 160px 10px 50px 10px;
}
.active1 {
  background-color: antiquewhite;
}

.left {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 33px;
}
.left h2 {
  width: 500px;
  margin: 10px 0;
}

.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  width: 500px;
  background-color: white;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

input:focus {
  outline: none;
}

form h3 {
  color: black;
  font-size: 30px;
  margin: 0 0 20px 0;
}

.input-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input,
textarea {
  width: 100%;
  min-width: 400px;
  max-width: 400px;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
}
textarea {
  min-height: 100px;
  max-height: 100px;
  overflow-y: auto;
}

.form-container button {
  padding: 12px 20px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.form-container button:hover {
  background-color: #45a049;
}
@media only screen and (max-width: 900px) {
  .container {
    flex-direction: column;
    flex-wrap: wrap;
    min-height: 80vh;
    padding: 120px 10px 50px 10px;
  }
  .left {
    margin: 1rem 0;
    font-size: 20px;

    text-align: center;
  }
  .left h2 {
    width: fit-content;
  }
  .form-container {
    width: 100%;
  }
  input {
    width: 90vw;
    min-width: 100px;
    max-width: 100%;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  textarea {
    width: 90vw;
    min-width: 100px;
    max-width: 100%;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
}
