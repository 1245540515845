.aboutsection {
  width: 100%;
  background-color: antiquewhite;
}
.aboutcontainer-div {
  position: relative;
  padding: 111px 40px 40px 40px;
  width: 900px;
  margin: auto;
}

.aboutcontainer-div h1 {
  text-align: center;
  color: #333;
  margin: 2rem auto;
}
.aboutcontainer-div h2 {
  color: #333;
  margin: 2rem 0 1rem 0;
}

.aboutcontainer-div p {
  font-size: 18px;
  line-height: 1.6;
  color: #666;
  text-align: justify;
  margin-bottom: 1rem;
}

blockquote {
  font-style: italic;
  margin: 20px 0;
  padding: 30px 10px;
  background-color: #f9f9f9;
  border-left: 4px solid #333;
}
blockquote p {
  margin-bottom: 1rem;
}

.motto-div {
  text-align: center;
  margin-top: 30px;
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.motto-div span {
  color: #0066cc;
  text-align: center;
  text-wrap: nowrap;
  font-size: 25px;
}

.aboutcontainer-div button {
  padding: 1rem 1.5rem;
  background-color: maroon;
  color: white;
  border-radius: 1rem;
  margin: auto;
}

.aboutcontainer-div a {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.aboutcontainer-div ul li {
  margin-bottom: 1.2rem;
  font-size: 18px;
  line-height: 1.6;
  color: #666;
  text-align: justify;
}

@media only screen and (max-width: 900px) {
  .aboutcontainer-div {
    position: relative;
    padding: 111px 10px 40px 10px;
    width: 100%;
    margin: auto;
  }
  .motto-div span {
    font-size: 18px;
    font-weight: bold;
    text-wrap: wrap;
  }
}
